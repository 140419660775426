import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react';
import useLanguage from '../hooks/useLanguage';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../hooks/useIsMobile';
import { isNL } from '../i18n/config';

const Description = () => {
  const { t } = useTranslation();

  return (
    <div
      className="mb-2 max-w-xs pr-12 font-flink-medium text-base drop-shadow-2xl md:max-w-xl md:pr-0 md:text-xl lg:max-w-full lg:drop-shadow-none [&>ul]:list-disc [&>ul]:pl-6"
      dangerouslySetInnerHTML={{ __html: t('hero_section.description-v2') }}
    />
  );
};

const HeroSection = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const navigate = useNavigate();
  const { isXS } = useScreenSize();

  const image = t('hero_section.image-v2');
  let imageStyles: React.CSSProperties = {};

  if (isNL(language) && isXS) {
    imageStyles = { backgroundPositionX: language === 'nl_en' ? -60 : -256 };
  }

  const sectionScrollHandle = (e: React.SyntheticEvent, value: string) => {
    e && e.preventDefault();
    const elementToView = document.getElementById(value);
    if (elementToView) {
      elementToView.scrollIntoView({ block: 'end', behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const scrollAndTrackApplyNow = (e: SyntheticEvent<Element, Event>) => {
    sectionScrollHandle(e, 'apply_now');
  };

  return (
    <section
      id="hero"
      className="-z-10 -mt-36 h-full bg-cover bg-center bg-no-repeat pt-72 pb-5 font-flink-bold md:overflow-hidden lg:pt-64 lg:pb-24"
      style={{ backgroundImage: `url(${image})`, ...imageStyles }}
    >
      <div className="relative z-10 mx-auto mb-14 flex max-w-1400">
        <div className="mx-auto flex w-full">
          <div className="hero-section-animated flex flex-col justify-center pl-5 pr-10 md:pl-5 md:pr-0 md:pb-5 lg:pb-0">
            <div className="mb-5">
              <h1
                className="font-flink-light text-6xl leading-none drop-shadow-xl md:text-7xl lg:text-9xl"
                dangerouslySetInnerHTML={{ __html: t('hero_section.title-v2') }}
              />
            </div>

            <Description />

            <div className="mt-12 mb-7 lg:mt-16 lg:mb-0">
              <a
                onClick={(e) => scrollAndTrackApplyNow(e)}
                className="primary-btn text-2xl tracking-wider"
              >
                {t('hero_section.button')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
