import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import useIsMobile from '../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { RoundedParallelogram } from './RoundedParallelogram';

const BecomeRiderSection = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const sections = Array.from({ length: 5 }, (_item, index) => ({
    title: t(`become_rider_menu.${index + 1}.title`),
    image: t(`become_rider_menu.${index + 1}.image-v2`),
  }));

  return (
    <section id="how_to_become_a_rider" className="relative px-5 pt-16 font-flink-bold">
      <div className="mx-auto flex max-w-7xl flex-col justify-center">
        <h3 className="mb-16 text-left font-flink-light text-5xl text-flinkPink md:mb-28">
          {t('become_rider.title')}
        </h3>

        {isMobile ? (
          <div className="-mt-4">
            <Carousel
              showArrows
              emulateTouch
              infiniteLoop
              showStatus={false}
              centerMode
              centerSlidePercentage={100}
              showThumbs={false}
            >
              {sections.map(({ title, image }, index) => {
                const isLastItem = index === sections.length - 1;

                return (
                  <div key={index} className="flex h-96 w-full items-center justify-center">
                    <RoundedParallelogram
                      className="h-68 absolute -z-10 w-full max-w-md"
                      fill={isLastItem ? '#E81F76' : '#F97FB5'}
                    />

                    <div className="relative flex w-full max-w-md items-end justify-between gap-2 px-6">
                      <img
                        className="min-w-36 max-w-48 !w-[46%]"
                        src={image}
                        width={120}
                        alt={`step ${title}`}
                      />

                      <h3 className="pb-16 font-flink-bold text-2xl">{title}</h3>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        ) : (
          <div className="relative flex flex-wrap items-start justify-center gap-x-2 gap-y-24 pt-32 text-left lg:gap-y-32 lg:gap-x-16 xl:gap-y-40 xl:gap-x-8">
            {sections.map(({ title, image }, index) => {
              const isLastItem = index === sections.length - 1;

              return (
                <div
                  key={index}
                  className="relative h-[220px] w-[370px] -translate-y-16 lg:w-[400px]"
                >
                  <RoundedParallelogram
                    className="absolute -z-10 w-[370px] -translate-y-16 -translate-x-3 lg:w-[400px]"
                    fill={isLastItem ? '#E81F76' : '#F97FB5'}
                  />

                  <div className="flex -translate-y-20 justify-between pl-4 pr-6">
                    <img className="w-36 lg:w-40" src={image} width={160} alt={`step ${title}`} />

                    <div className="relative flex-1 px-4">
                      {!isLastItem && (
                        <p className="absolute -top-3 right-4 text-9xl text-flinkPink">
                          {index + 1}
                        </p>
                      )}
                      <h3 className="absolute bottom-12 w-44 font-flink-bold text-xl md:text-lg lg:text-2xl">
                        {title}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {!isMobile && (
        <RoundedParallelogram
          width="45vw"
          className="absolute -z-20 -translate-x-40 -translate-y-16 rotate-6"
          fill="#002855"
        />
      )}
    </section>
  );
};

export default BecomeRiderSection;
