import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { RoundedParallelogram } from './RoundedParallelogram';
import useIsMobile from '../hooks/useIsMobile';

const getItems = (t: TFunction) =>
  Array.from({ length: 6 }, (_item, index) => ({
    title: t(`what_we_offer_section.${index}.title`),
    description: t(`what_we_offer_section.${index}.description`),
    image: '../' + t(`what_we_offer_section.${index}.image`),
  }));

const OfferSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const items = getItems(t);

  return (
    <section
      id="what_we_offer"
      className="relative py-36 px-5 font-flink-regular text-flinkNightBlue"
    >
      <RoundedParallelogram
        height={500}
        className="absolute right-24 -top-[460px] -z-10 rotate-6"
        fill="#E81F76"
      />

      <div className="mx-auto flex max-w-6xl flex-col justify-center">
        <h3 className="mb-24 font-flink-light text-5xl text-flinkPink">
          {t('menu_what_we_offer')}
        </h3>

        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:gap-20 xl:grid-cols-3">
          {items.map(({ image, title, description }, index) => (
            <div key={index} className="offer-section-animated md:px-5 lg:px-0">
              <div className="flex items-start justify-between gap-4 md:gap-6">
                <img alt={title} src={image} width={isMobile ? 100 : 80} />

                <div>
                  <div className="mt-2 mb-4 break-words font-flink-bold text-2xl md:text-3xl">
                    <h3>{title}</h3>
                  </div>
                  <div className="font-flink-regular text-base md:text-lg">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
