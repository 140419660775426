import { useEffect, useRef } from 'react';
import Icon from './Icon';

import useOutsideClick from '../hooks/useOutsideClick';

type Props = {
  isOpen?: boolean;
  onCancel: () => void;
  children: React.ReactNode;
};

const Modal = ({ isOpen, onCancel, children }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    // disable background scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);

  useOutsideClick(ref, () => {
    if (isOpen) {
      onCancel();
    }
  });

  return isOpen ? (
    <div className="fixed inset-0 z-50 overflow-y-auto" role="dialog" aria-modal="true">
      <div className="flex min-h-screen items-start justify-center pt-4 pb-20">
        <div
          className="fixed inset-0 bg-black bg-opacity-60 transition-opacity"
          aria-hidden="true"
        ></div>
        <div
          ref={ref}
          className="mx-auto mt-40 flex w-full max-w-2xl transform flex-col overflow-hidden rounded-lg text-left align-bottom text-white shadow-xl transition-all"
        >
          <Icon
            icon="cross"
            size={26}
            className="absolute right-3 pr-2 pt-2 text-pink-deep"
            onClick={onCancel}
          />

          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
