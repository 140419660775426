const setCookieConsent = (value: boolean) => {
  if (value) {
    document.cookie = 'CookieConsent=true; path=/; max-age=31536000';
  } else {
    document.cookie = 'CookieConsent=false; path=/; max-age=31536000';
  }
};

const getCookieConsentValue = () => {
  const name = 'CookieConsent=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export { setCookieConsent, getCookieConsentValue };
