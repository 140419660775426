import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './de.json';
import de_en from './de_en.json';
import fr from './fr.json';
import fr_en from './fr_en.json';
import nl from './nl.json';
import nl_en from './nl_en.json';

export const isDE = (lng: string) => lng.startsWith('de');
export const isNL = (lng: string) => lng.startsWith('nl');

export const resources = {
  de_en: { translation: de_en },
  de: { translation: de },
  fr: { translation: fr },
  fr_en: { translation: fr_en },
  nl: { translation: nl },
  nl_en: { translation: nl_en },
} as const;

i18n.use(initReactI18next).init({
  lng: 'de_en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
  fallbackLng: 'de_en',
});
