import { BOOLEAN_FLAG, type FlagDefinitions } from '../types/experimentation';

export const FLAGS: FlagDefinitions = {
  NLSalaryCalculator: {
    experimentKey: '_wm__rider_landing_page_nl_salary_calculator',
    variation: BOOLEAN_FLAG.ON,
  },
  DETestimonials: {
    experimentKey: '_wm__rider_landing_page_de_testimonials_section_new_video',
    variation: BOOLEAN_FLAG.ON,
  },
};
