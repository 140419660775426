import IcoMoon from 'react-icomoon';

const iconSet = require('./selection.json');

interface IconProps {
  className?: string;
  icon: string;
  size: number;
  onClick?: () => void;
}

const Icon = (props: IconProps) => <IcoMoon iconSet={iconSet} {...props} />;

export default Icon;
