import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CookieConsent from '../components/CookieConsent';
import Header from '../components/Header';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import '../i18n/config';

const LandingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('de_en');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('meta-title')}</title>
        <meta name="description" content={t('meta-description') || ''} />
      </Helmet>

      <Header showMenu={false} applyButtonLink={'/'} />

      <section
        className="-z-10 -mt-36 h-full bg-cover bg-center bg-no-repeat px-5 py-5 pt-11 font-flink-bold"
        style={{ backgroundImage: `url(${t('hero_section.image-v2')})` }}
      >
        <div className="mx-auto flex min-h-screen max-w-6xl flex-col justify-center gap-5">
          <h1
            className="font-flink-light text-6xl drop-shadow-xl md:text-7xl lg:text-9xl"
            dangerouslySetInnerHTML={{ __html: t('hero_section.title-v2') }}
          />

          <div className="mb-3 max-w-xs text-xl md:mb-12 md:max-w-md md:text-2xl lg:text-3xl">
            {t('language_selection_page_message')}
          </div>

          <div className="text-2xl md:text-3xl lg:text-4xl">{t('select_your_country')}</div>

          <section id="apply_now_language_selection" className="w-full justify-center">
            <div className="flag-container flex flex-wrap gap-8 md:gap-20">
              <Link to={`/de${location.search}`}>
                <img
                  src="/images/de.png"
                  alt="Germany"
                  width={125}
                  height={125}
                  className="w-28 md:w-32"
                />
              </Link>
              <Link to={`/nl${location.search}`}>
                <img
                  src="/images/nl.png"
                  alt="Netherlands"
                  width={125}
                  height={125}
                  className="w-28 md:w-32"
                />
              </Link>
            </div>
          </section>
        </div>
      </section>
      <CookieConsent />
    </>
  );
};

export default LandingPage;
