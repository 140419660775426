import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <section className="relative min-h-screen w-full bg-gray-cultured px-4 pb-20 pt-6 font-flink-regular text-sm text-gray-sonic_silver md:text-lg">
      <div className="mx-auto h-full max-w-5xl">
        <div className="mb-5 flex flex-row">
          <Link to={`/${location.search}`}>
            <a className="flex flex-row text-pink-deep">
              <div>
                <Icon icon="backward" size={50} className="text-pink-deep" />
              </div>
              <div>{t('privacy.back_to_homepage')}</div>
            </a>
          </Link>
        </div>
        <div className="mb-12">
          <h1 className="font-flink-bold text-3xl text-pink-deep md:text-4xl lg:text-5xl">
            {t('privacy.title')}
          </h1>
        </div>
        <div className="privacy-content mb-2 text-sm md:text-lg">
          <h2 className="mb-4 font-flink-bold text-xl md:text-2xl lg:text-3xl">
            <b>{t('privacy.description_title')}</b>
          </h2>
          <p>
            {t('privacy.description.text')}
            <a
              href={t('privacy.description.link') || ''}
              target="_blank"
              className="text-pink-deep"
              rel="noreferrer"
            >
              {t('privacy.description.link.text')}
            </a>
          </p>
          <div dangerouslySetInnerHTML={{ __html: t('privacy_page.content') }}></div>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
