export const BOOLEAN_FLAG = {
  FALSE: 'false',
  TRUE: 'true',
  OFF: 'off',
  ON: 'on',
} as const;

export type BooleanString = typeof BOOLEAN_FLAG[keyof typeof BOOLEAN_FLAG];

export type FlagKey = string;

export interface FlagDecision<TFlagKey> {
  experiment?: string;
  flag: TFlagKey;
  variation: BooleanString;
}

export type Decisions = Record<FlagKey, FlagDecision<FlagKey>>;

export interface FlagDefinition {
  experimentKey: FlagKey;
  variation: BooleanString;
}

export type FlagDefinitions = Record<string, FlagDefinition>;
