import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import Privacy from '../components/Privacy';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('meta.privacy.title')}</title>
        <meta name="description" content={t('meta.privacy.description') || ''} />
      </Helmet>
      <Header showMenu={false} applyButtonLink={'/'} />
      <Privacy />
      <Footer showBottomButton={false} />
    </>
  );
};

export default PrivacyPage;
