import { createInstance, useDecision } from '@optimizely/react-sdk';

export const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
  eventBatchSize: 10,
  eventFlushInterval: 1000,
});

export const IsFeatureEnabled = (experiment: string, variation: string | null): boolean => {
  const [decision] = useDecision(experiment);

  if (decision?.variationKey === variation) {
    return true;
  }

  return false;
};
