import { useLocation } from 'react-router-dom';

const useLanguage = () => {
  const location = useLocation();

  const { pathname } = location;
  const language = pathname.split('/')[1];

  return language;
};

export default useLanguage;
