import { useEffect, useState } from 'react';
import { optimizely } from '../helpers/optimizely';
import { useUniqueUserId } from './useFlags';
import { useCountry } from './useCountry';

export const useSignupConversionTracking = () => {
  const [isReady, setIsReady] = useState(false);
  const userId = useUniqueUserId();
  const country = useCountry();

  const enableTracking = () => setIsReady(true);

  useEffect(() => {
    const handler = (event: MessageEvent<{ version: string; eventName: string }>) => {
      const allowedOrigins = [
        'http://localhost:3000',
        'https://riders.goflink.com',
        'https://web.fountain.com',
      ];
      const origin = new URL(event.origin).origin;

      if (!allowedOrigins.includes(origin)) return;

      if (event.data.eventName === 'conversion') {
        console.log('sending conversion event');

        const attributes = {
          country,
        };

        optimizely.track('conversion', userId, attributes);
      }
    };

    isReady && window.addEventListener('message', handler);
  }, [isReady]);

  return { enableTracking };
};
