import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import useLanguage from '../hooks/useLanguage';
import Icon from './Icon';
import cn from 'classnames';

type AccordionItemProps = {
  key: number;
  title: string;
  onClick: () => void;
  expanded: boolean;
  children: JSX.Element;
};

const AccordionItem: FC<AccordionItemProps> = ({
  key,
  title,
  children,
  onClick,
  expanded = false,
}) => (
  <div className="mb-4">
    <h2
      className={cn(
        'accordion-element text-bold transition-all duration-500',
        expanded
          ? ['expanded', 'bg-flinkPink', 'text-white']
          : ['bg-flinkRose-light', 'text-flinkNightBlue'],
      )}
      id={`accordion-heading-${key}`}
    >
      <button
        className="w-full px-12 py-4 text-left font-flink-bold text-base md:text-xl"
        type="button"
        aria-expanded={expanded}
        aria-controls={`accordion-${key}`}
        onClick={onClick}
      >
        {title}
        <span className={cn('float-right', expanded ? 'text-white' : 'text-flinkPink')}>
          <Icon icon={expanded ? 'minus' : 'plus'} size={14} />
        </span>
      </button>
    </h2>
    <div
      id={`accordion-${key}`}
      className={cn(
        'overflow-hidden transition-[max-height] duration-500',
        expanded ? 'max-h-96' : 'max-h-0',
      )}
      aria-labelledby={`accordion-heading-${key}`}
    >
      <div className="px-12 pt-4 pb-10 font-flink-light text-flinkNightBlue md:text-lg">
        {children}
      </div>
    </div>
  </div>
);

const getQuestions = (language: string, t: TFunction) => {
  const count = language === 'fr' || language === 'fr_en' ? 4 : 5;

  return Array.from({ length: count }, (_item, index) => ({
    title: t(`faq_section.${index}.title`),
    answer: t(`faq_section.${index}.answer`),
  }));
};

const FAQSection = () => {
  const { t } = useTranslation();
  const [expandedElement, setExpanded] = useState<number | null>(null);
  const language = useLanguage();

  const questions = getQuestions(language, t);

  return (
    <section id="faqs" className="py-16 px-5 font-flink-bold text-white">
      <div className="mx-auto max-w-6xl">
        <h3 className="mb-14	text-left font-flink-light text-5xl tracking-wide text-flinkPink">
          {t('menu_faqs')}
        </h3>

        <div className="mx-auto max-w-3xl">
          {questions.map(({ title, answer }, index) => (
            <AccordionItem
              key={index}
              title={title}
              onClick={() => {
                setExpanded(expandedElement === index ? null : index);
              }}
              expanded={expandedElement === index}
            >
              <span className="faq-item" dangerouslySetInnerHTML={{ __html: answer }} />
            </AccordionItem>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
