import YoutubeEmbed from './YoutubeEmbed';
import { useTranslation } from 'react-i18next';
import { isDE } from '../i18n/config';
import type { TFunction } from 'i18next';
import useLanguage from '../hooks/useLanguage';

type Testimonial = {
  video: string;
  description: string;
  name: string;
  country: string;
};

const getTestimonials = (t: TFunction, showSingleTestimonial = false): Testimonial[] => {
  if (showSingleTestimonial) {
    return [
      {
        video: t('testimonial.new.0.video'),
        description: t('testimonial.new.0.description'),
        name: t('testimonial.new.0.name'),
        country: t('testimonial.new.0.country'),
      },
    ];
  }

  return Array.from({ length: 2 }, (_item, index) => ({
    video: t(`testimonial.${index}.video`),
    description: t(`testimonial.${index}.description`),
    name: t(`testimonial.${index}.name`),
    country: t(`testimonial.${index}.country`),
  }));
};

const TestimonialsSection = () => {
  const { t } = useTranslation();
  const language = useLanguage();

  const testimonials = getTestimonials(t, isDE(language));

  const containerGridClasses =
    testimonials.length === 1
      ? 'grid grid-cols-1'
      : 'grid grid-cols-1 md:grid-cols-2 md:gap-10 lg:grid-cols-2 lg:gap-20';

  return (
    <section id="testimonials" className="bg-white py-16 px-5 font-flink-light">
      <div className="mx-auto flex max-w-6xl">
        <div className="flex flex-col">
          <h3 className="mb-16 font-flink-light text-5xl text-flinkPink">
            {t('menu_testimonials')}
          </h3>
          <div className={containerGridClasses}>
            {testimonials.map(({ video, description, name, country }, index) => (
              <div key={index} className="mb-7 lg:mr-8">
                <div className="mb-7">
                  <YoutubeEmbed
                    embedId={video}
                    orientation={testimonials.length === 1 ? 'vertical' : 'horizontal'}
                  />
                </div>
                <div className="mb-6 text-sm text-flinkNightBlue lg:text-xl">
                  <p>{description}</p>
                </div>
                <div className="inline-block w-72">
                  <p className="font-flink-regular text-3xl text-flinkPink">{name}</p>
                  <span className="text-sm text-flinkRose-dark">{country}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
