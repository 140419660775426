import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getCookieConsentValue, setCookieConsent } from '../helpers/cookies';

interface CookieModalProps {
  isManageCookiesModalVisible: boolean;
  setIsManageCookiesModalVisible: (value: boolean) => void;
}

const CookieModal = ({
  isManageCookiesModalVisible,
  setIsManageCookiesModalVisible,
}: CookieModalProps) => {
  const { t } = useTranslation();
  const cookieConsentValue = getCookieConsentValue();
  const [allowMarketing, setAllowMarketing] = useState(cookieConsentValue === 'true');

  const savePreferences = () => {
    if (allowMarketing) {
      setCookieConsent(true);
    } else {
      setCookieConsent(false);
    }

    setIsManageCookiesModalVisible(false);
    location.reload();
  };

  return (
    <Modal
      isOpen={isManageCookiesModalVisible}
      onCancel={() => setIsManageCookiesModalVisible(false)}
    >
      <div className="job-decription-modal h-800 flex flex-col items-start overflow-auto bg-white px-10 py-10 text-left text-blue-prussian">
        <h2 className="mb-4 mt-2 font-flink-bold text-3xl">{t('cookie_consent_modal_title')}</h2>
        <hr />
        <p className="text-sm text-gray-600">{t('cookie_consent_modal_paragraph')}</p>
        <table className="table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">{t('cookie_consent_modal_table_allow')}</th>
              <th className="px-4 py-2">{t('cookie_consent_modal_table_category')}</th>
              <th className="px-4 py-2">{t('cookie_consent_modal_table_purpose')}</th>
              <th className="px-4 py-2">{t('cookie_consent_modal_table_tools')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="yes"
                    name="marketing"
                    value="yes"
                    className="form-radio"
                    checked={allowMarketing}
                    onChange={() => setAllowMarketing(true)}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    id="no"
                    name="marketing"
                    value="no"
                    className="form-radio"
                    checked={!allowMarketing}
                    onChange={() => setAllowMarketing(false)}
                  />
                  <label htmlFor="no">No</label>
                </div>
              </td>
              <td className="border px-4 py-2">
                {t('cookie_consent_modal_table_marketing_heading')}
              </td>
              <td className="border px-4 py-2">
                {t('cookie_consent_modal_table_marketing_paragraph')}
              </td>
              <td className="border px-4 py-2">
                Segment, Pixel, Fountain, TikTok Analytics, Google Analytics, Facebook Connect,
                Double Click, Affiliate-Programs, Programmatic Job Advertising
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2">N/A</td>
              <td className="border px-4 py-2">
                {t('cookie_consent_modal_table_essential_heading')}
              </td>
              <td className="border px-4 py-2">
                {t('cookie_consent_modal_table_essential_paragraph')}
              </td>
              <td className="border px-4 py-2"></td>
            </tr>
          </tbody>
        </table>
        <div className="mt-4 flex w-full items-center justify-end gap-8">
          <button className="manage-button" onClick={() => setIsManageCookiesModalVisible(false)}>
            {t('cookie_consent_modal_cancel')}
          </button>
          <button className="accept-button" onClick={savePreferences}>
            {t('cookie_consent_modal_save')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CookieModal;
