import YouTube from 'react-youtube';
interface YoutubeEmbedProps {
  embedId: string;
  orientation?: 'horizontal' | 'vertical';
}

const YoutubeEmbed = ({ embedId, orientation = 'horizontal' }: YoutubeEmbedProps) => {
  return (
    <div className={`video-responsive --${orientation}`}>
      <YouTube videoId={embedId} title="Testimonials" />
    </div>
  );
};

export default YoutubeEmbed;
