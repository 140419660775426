import { useEffect } from 'react';

type UseScriptProps = {
  src: string;
  condition: boolean;
};

const useScript = ({ src, condition }: UseScriptProps): void => {
  useEffect(() => {
    if (condition) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [src, condition]);
};

export default useScript;
