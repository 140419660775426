import { create } from 'zustand';
import type { Decisions } from '../types/experimentation';

interface FlagsState {
  decisions: Decisions | null;
  setDecisions: (decisions: Decisions) => void;
}

export const useFlagsStore = create<FlagsState>((set) => ({
  decisions: null,
  setDecisions: (decisions: Decisions) => set({ decisions }),
}));
