import { useScreen, useWindowSize } from 'usehooks-ts';

const BREAKPOINTS = {
  XS: 320,
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1440,
  '3XL': 1920,
};

const MobileWindowSize = BREAKPOINTS.MD;

export default function useIsMobile() {
  const screen = useScreen();
  return (screen?.width ?? BREAKPOINTS.XS) <= MobileWindowSize;
}

export function useScreenSize() {
  const { width, height } = useWindowSize();

  return {
    width,
    height,
    isXS: width <= BREAKPOINTS.XS,
    isSM: width > BREAKPOINTS.XS && width <= BREAKPOINTS.SM,
    isMD: width > BREAKPOINTS.SM && width <= BREAKPOINTS.MD,
    isLG: width > BREAKPOINTS.MD && width <= BREAKPOINTS.LG,
    isXL: width > BREAKPOINTS.LG && width <= BREAKPOINTS.XL,
    is2XL: width > BREAKPOINTS.XL && width <= BREAKPOINTS['2XL'],
    is3XL: width > BREAKPOINTS['2XL'],
  };
}
