import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { nanoid } from 'nanoid';
import {
  UserTrackingServiceApi,
  V1Platform,
  type V1ExperimentLookupRequest,
} from '@flink/experiment';
import { useCountry } from './useCountry';
import { FLAGS } from '../constants/flags';
import { useFlagsStore } from '../store/flags';
import { type Decisions, type FlagDefinition } from '../types/experimentation';

const api = new UserTrackingServiceApi(
  {},
  `${process.env.REACT_APP_API_BASE_URL}/user-tracking-gateway/user-tracking-gateway`,
);

export function useUniqueUserId() {
  // TODO: update the localStorage key name once existing A/B tests on Optimizely are concluded
  const [uniqueUserId, setIUniqueUserId] = useLocalStorage('optimizelyUserId', '');

  useEffect(() => {
    if (!uniqueUserId) {
      const userId = nanoid(11);
      setIUniqueUserId(userId);
    }
  }, []);

  return uniqueUserId;
}

export const useFlagDecisions = () => {
  const userId = useUniqueUserId();
  const country = useCountry();
  const existingDecisions = useFlagsStore((state) => state.decisions);
  const setDecisions = useFlagsStore((state) => state.setDecisions);

  useEffect(() => {
    const body: V1ExperimentLookupRequest = {
      userTrackingId: '',
      userId,
      meta: {
        attributes: {
          country_iso: country,
        },
      },
      flags: [...Object.values(FLAGS).map((flag) => flag.experimentKey)],
      platform: V1Platform.OptimizelyLastMile,
    };

    async function fetchExperiment() {
      const result = await api.userTrackingServiceExperimentLookup(body);
      const decisions = result.data.decisions as unknown as Decisions;
      setDecisions(decisions);

      console.log('fetchExperiment: ', decisions);
    }

    if (!existingDecisions) {
      fetchExperiment();
    }
  }, [userId, country, existingDecisions]);
};

type UseFlagResult = {
  enabled: boolean;
  isClientReady: boolean;
};

export function useFlagResult(flag: FlagDefinition): UseFlagResult {
  const result = { isClientReady: false, enabled: false };
  const { decisions } = useFlagsStore();

  if (decisions === null) {
    return result;
  }

  const decision = decisions[flag.experimentKey];

  result.isClientReady = true;
  if (decision) {
    result.enabled = decision.variation === flag.variation;
  }

  return result;
}
