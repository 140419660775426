import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react';

const ReadySection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sectionScrollHandle = (e: React.SyntheticEvent, value: string) => {
    e && e.preventDefault();
    const elementToView = document.getElementById(value);
    if (elementToView) {
      elementToView.scrollIntoView({ block: 'end', behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const scrollAndTrackApplyNow = (e: SyntheticEvent<Element, Event>) => {
    sectionScrollHandle(e, 'apply_now');
  };

  return (
    <section>
      <div className="flex w-full flex-col justify-center bg-blue-prussian pb-20 pt-16 pr-5 pl-5 text-center font-flink-bold md:py-24 md:px-12 lg:py-48 ">
        <div className="ready-section-animated mb-2 md:mb-5">
          <h2 className="text-66 leading-none text-pink-persian md:text-7xl lg:text-8xl">
            {t('ready_section_first_part')}
            <span className="text-white"> {t('ready_section_second_part')}</span>
          </h2>
        </div>
        <div className="mb-14 font-flink-regular md:text-3xl">{t('ready_section_description')}</div>
        <div>
          <a
            onClick={(e) => scrollAndTrackApplyNow(e)}
            className="primary-btn text-xl tracking-wider md:text-2xl"
          >
            {t('ready_section_button')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default ReadySection;
