import useLanguage from '../hooks/useLanguage';
import useScript from '../hooks/useScript';
import { isNL } from '../i18n/config';

// This class name is mandatory for the uCalc script to render the form in the correct place
const EMBEDDED_FORM_CLASSNAME_PREFIX = 'uCalc_';

const FORM_IDS = {
  en: '452205',
  nl: '451032',
};

const getFormId = (language: string) => (language.endsWith('en') ? FORM_IDS.en : FORM_IDS.nl);
const getFormContainerClassname = (language: string) =>
  EMBEDDED_FORM_CLASSNAME_PREFIX + getFormId(language);

export default function SalaryCalculatorSection() {
  const language = useLanguage();
  const shouldRender = isNL(language);

  useScript({
    src: `https://ucalc.pro/api/widget.js?id=${getFormId(language)}&t=${Math.floor(
      new Date().getTime() / 18e5,
    )}`,
    condition: shouldRender,
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <section id="salary_calculator" className="bg-flinkRose-light py-16 px-5">
      <div className={getFormContainerClassname(language)}></div>
    </section>
  );
}
