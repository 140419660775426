import Icon from './Icon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useLanguage from '../hooks/useLanguage';

type HeaderProps = {
  applyButtonLink: string;
  showMenu: boolean;
  height?: number;
  showApplyButton?: boolean;
};

const MENU = ['what_we_offer', 'how_to_become_a_rider', 'testimonials', 'faqs', 'apply_now'];

const Header = ({ applyButtonLink, showMenu, showApplyButton = true }: HeaderProps) => {
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();

  const qs = search;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDir, setScrollDir] = useState('no scrolling');

  const onMenuChange = () => setIsMenuOpen(!isMenuOpen);

  const sectionScrollHandle = (e: React.SyntheticEvent, value: string) => {
    setIsMenuOpen(false);
    e && e.preventDefault();
    const elementToView = document.getElementById(value);
    if (elementToView) {
      elementToView.scrollIntoView({ block: 'end', behavior: 'smooth' });
    } else {
      navigate(qs ? `/${qs}` : '/');
    }
  };

  useEffect(() => {
    if (!isMenuOpen) {
      const threshold = 0;
      let lastScrollY = window.pageYOffset;
      let ticking = false;

      const updateScrollDir = () => {
        const scrollY = window.pageYOffset;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        setScrollDir(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };

      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };

      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [scrollDir, isMenuOpen]);

  return (
    <header
      className={cn(
        'relative z-40 font-flink-bold md:text-xs',
        scrollDir === 'scrolling up' && ['sticky', 'top-0'],
      )}
    >
      <div
        className={cn('bg-flinkPink', {
          block: scrollDir !== 'scrolling down',
          'bg-opacity-60': !isMenuOpen,
        })}
      >
        <div className="mx-auto my-0 max-w-1400">
          <div className="relative flex h-14 items-center justify-between py-2.5 px-5 md:h-20">
            <div className="md:after:w-70 after:absolute after:-left-6 after:top-0 after:h-14 after:w-48 after:-skew-x-[30deg] after:bg-flinkNightBlue md:pl-4 md:after:h-20 md:after:w-56 lg:after:-left-[31.5rem] lg:after:w-[45rem]">
              <Link to={`/${location.search}`}>
                <a>
                  <img
                    className="relative z-10 w-32 md:w-36 lg:w-40"
                    src="../images/flink-logo.svg"
                    alt="Flink Logo"
                    width={150}
                  />
                </a>
              </Link>
            </div>
            {showMenu && (
              <div className="md:hidden">
                <Icon icon={isMenuOpen ? 'cross' : 'menu'} size={26} onClick={onMenuChange} />
              </div>
            )}
            <div className="hidden items-center md:flex">
              {showApplyButton && (
                <div className="mr-8 text-xl lg:text-3xl">{t('become_a_rider')}</div>
              )}
              {showMenu && (
                <div className="mr-8">
                  <Link
                    to={
                      language.includes('_en')
                        ? `/${language.replace('_en', '')}${location.search}`
                        : `/${language}_en${location.search}`
                    }
                  >
                    <a>
                      <img
                        src={
                          language.includes('_en')
                            ? `../images/${language.replace('_en', '')}.png`
                            : '../images/british.png'
                        }
                        alt="Flag"
                        width={45}
                        height={40}
                        className="h-[40px] w-[45px] rounded-xl"
                      />
                    </a>
                  </Link>
                </div>
              )}
              {showApplyButton && (
                <button
                  onClick={(e) => sectionScrollHandle(e, applyButtonLink)}
                  className="primary-btn bg-flinkNightBlue tracking-wider"
                >
                  {t('apply_now')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(
          'bg-flinkPink bg-opacity-60',
          scrollDir === 'scrolling down' && !isMenuOpen ? ['header-sticky', 'top-0', 'z-50'] : [''],
        )}
      >
        <div className="mx-auto max-w-1400">
          <div className="flex h-12 items-center justify-center bg-flinkPink bg-opacity-0 text-lg text-white md:hidden">
            <span>{t('become_a_rider')}</span>
            {showMenu && (
              <div className="ml-4 flex justify-center">
                <Link
                  to={
                    language.includes('_en') ? `/${language.replace('_en', '')}` : `/${language}_en`
                  }
                >
                  <a>
                    <img
                      src={
                        language.includes('_en')
                          ? `../images/${language.replace('_en', '')}.png`
                          : '../images/british.png'
                      }
                      alt="Flag"
                      width={45}
                      height={40}
                      className="h-[40px] w-[45px] rounded-xl"
                    />
                  </a>
                </Link>
              </div>
            )}
          </div>
          {isMenuOpen && (
            <div className="fixed top-0 left-0 z-30 mt-14 flex h-full w-full flex-col justify-center overflow-hidden bg-flinkPink text-center text-lg text-white">
              {MENU.map((value, index) => {
                return (
                  <div className="cursor-pointer px-4 py-3.5" key={index}>
                    <a onClick={(e) => sectionScrollHandle(e, value)}>{t(`${value}`)}</a>
                  </div>
                );
              })}
            </div>
          )}
          {showMenu && (
            <div className="hidden items-center justify-end bg-flinkPink bg-opacity-0 px-2.5 text-base text-white md:flex">
              <div className="flex flex-row ">
                {MENU.map((value, index) => (
                  <div
                    className="cursor-pointer px-4 py-3.5 decoration-solid decoration-3 underline-offset-4 hover:underline"
                    key={index}
                  >
                    <a onClick={(e) => sectionScrollHandle(e, value)}>{t(`${value}`)}</a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
