import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import '../i18n/config';
import NotFound from './NotFound';
import LandingPage from './LandingPage';
import PrivacyPage from './Privacy';
import Language from './Language';
import Redirect from './Redirect';

import { OptimizelyProvider } from '@optimizely/react-sdk';
import { getCookieConsentValue } from '../helpers/cookies';
import { optimizely } from '../helpers/optimizely';
import AppContainer from '../components/AppContainer';
import { Helmet } from 'react-helmet';
import { useFlagDecisions, useUniqueUserId } from '../hooks/useFlags';
import { useCountry } from '../hooks/useCountry';

const AppProviders = ({ children }: React.PropsWithChildren<unknown>) => {
  const userId = useUniqueUserId();
  const cookieConsent = getCookieConsentValue() === 'true';
  const country = useCountry();
  const gtmKey = process.env.REACT_APP_GTM_KEY;

  useFlagDecisions();

  return (
    <>
      <Helmet>
        {cookieConsent && (
          <script src="https://jsv3.recruitics.com/806c3552-a8ee-11eb-90f1-4ba7be31c25c.js"></script>
        )}
        {cookieConsent && gtmKey && (
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmKey}');`}</script>
        )}
      </Helmet>
      <OptimizelyProvider
        optimizely={optimizely}
        user={{
          id: userId,
          attributes: { country },
        }}
      >
        <BrowserRouter>
          <AppContainer>{children}</AppContainer>
        </BrowserRouter>
      </OptimizelyProvider>
    </>
  );
};

const AppRoutes = () => {
  const queryString = window.location.search;

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/:lang" element={<Language />} />

      {/* REDIRECTS */}
      <Route path="/seidabei" element={<Navigate to={`/de${queryString}`} replace />} />
      <Route path="/join" element={<Navigate to={`/de_en${queryString}`} replace />} />
      <Route path="/de-en" element={<Navigate to={`/de_en${queryString}`} replace />} />
      <Route path="/nl-nl" element={<Navigate to={`/nl${queryString}`} replace />} />
      <Route path="/nl-en" element={<Navigate to={`/nl_en${queryString}`} replace />} />
      <Route path="/privacy-policy" element={<Navigate to={`/privacy${queryString}`} replace />} />
      <Route
        path="/privacy-policy-de"
        element={<Navigate to={`/privacy${queryString}`} replace />}
      />

      {/* HUBSPOT REDIRECTS */}
      <Route path="/hubspot" element={<Redirect />} />
      <Route path="/hubspot/de" element={<Redirect />} />
      <Route path="/hubspot/nl" element={<Redirect />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default App;
