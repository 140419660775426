import useIsMobile from '../hooks/useIsMobile';
import Icon from './Icon';
import Modal from './Modal';
import { Link, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import { useState, useCallback, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useLanguage from '../hooks/useLanguage';
import CookieConsent from '../components/CookieConsent';
import CookieModal from './CookieModal';

type footerProps = {
  showBottomButton: boolean;
};

const Footer = ({ showBottomButton }: footerProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [scrollY, setScrollY] = useState<number>(0);
  const language = useLanguage();
  const navigate = useNavigate();

  const [isJobDescriptionModalVisible, setIsJobDescriptionModalVisible] = useState(false);
  const [pickerJobDescriptionModalVisible, setPickerJobDescriptionModalVisible] = useState(false);
  const [isManageCookiesModalVisible, setIsManageCookiesModalVisible] = useState(false);

  const sectionScrollHandle = (e: React.SyntheticEvent, value: string) => {
    e && e.preventDefault();
    const elementToView = document.getElementById(value);
    if (elementToView) {
      elementToView.scrollIntoView({ block: 'end', behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const scrollAndTrackApplyNow = (e: SyntheticEvent<Element, Event>) => {
    sectionScrollHandle(e, 'apply_now');
  };

  const date = new Date();
  const year = date.getFullYear();

  const onScroll = useCallback(() => {
    const { pageYOffset } = window;

    setScrollY(pageYOffset);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const openJobDescriptionModal = () => {
    setIsJobDescriptionModalVisible(true);
  };

  return (
    <section className="bg-pink-deep py-11 px-10 text-center font-flink-regular text-sm md:py-20 md:text-base">
      <div className="mx-auto my-0 max-w-1400">
        <div className="flex w-full flex-col items-center md:flex-row md:justify-between">
          <div className="footer-logo-container mb-10 md:mb-0">
            <img src="../../images/footer-logo.svg" width={180} height={45} alt="Flink Logo" />
          </div>
          <div className="mb-5 md:mb-0">
            <div className="mb-5 cursor-pointer font-flink-bold text-base">
              <div className="cursor-pointer" onClick={openJobDescriptionModal}>
                {t('footer_job_description')}
              </div>
              {(language === 'nl' || language === 'nl_en') && (
                <div
                  className="cursor-pointer"
                  onClick={() => setPickerJobDescriptionModalVisible(true)}
                >
                  {t('footer_rider_job_description')}
                </div>
              )}
            </div>
            <div>
              © {year} {t('company_name')}
            </div>
          </div>
          <div>
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div className="mb-5 md:mb-0 md:mr-4">
                <a href="https://www.goflink.com/en-DE/imprint" target="_blank" rel="noreferrer">
                  {t('imprint')}
                </a>
              </div>
              <div className="mb-5 md:mb-0 md:mr-4">
                <Link to={`/privacy${location.search}`}>
                  <a>{t('privacy.footer_title')}</a>
                </Link>
              </div>
              <div className="flex md:mt-2.5 md:mr-4 lg:m-auto">
                <span className="mr-2.5">
                  <a href="https://www.instagram.com/workatflink/" target="_blank" rel="noreferrer">
                    <Icon icon="instagram1" size={isMobile ? 40 : 50} />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.linkedin.com/company/goflink/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon icon="linkedin2" size={isMobile ? 40 : 50} />
                  </a>
                </span>
              </div>
              <CookieConsent />
            </div>
            <div
              className="mb-5 cursor-pointer text-left md:mb-0 md:mr-4"
              onClick={() => setIsManageCookiesModalVisible(true)}
            >
              {t('cookie_consent_footer_preferences')}
            </div>
          </div>
        </div>
        <Modal
          isOpen={isJobDescriptionModalVisible}
          onCancel={() => setIsJobDescriptionModalVisible(false)}
        >
          <div className="job-decription-modal flex h-600 flex-col items-start overflow-auto bg-white px-10 py-10 text-left text-blue-prussian">
            <h2 className="mb-4 mt-2 font-flink-bold text-3xl">{t('job_description.title')}</h2>
            <div
              className="mt-2 text-sm"
              dangerouslySetInnerHTML={{ __html: t('job_description.content') }}
            ></div>
          </div>
        </Modal>
        <Modal
          isOpen={pickerJobDescriptionModalVisible}
          onCancel={() => setPickerJobDescriptionModalVisible(false)}
        >
          <div className="job-decription-modal flex h-600 flex-col items-start overflow-auto bg-white px-10 py-10 text-left text-blue-prussian">
            <h2 className="mb-4 mt-2 font-flink-bold text-3xl">
              {t('job_description.picker.title')}
            </h2>
            <div
              className="mt-2 text-sm"
              dangerouslySetInnerHTML={{ __html: t('job_description.picker.content') }}
            ></div>
          </div>
        </Modal>
        <div
          className={cn(
            'fixed bottom-0 left-0 right-0 z-10 items-center font-flink-medium text-lg',
            scrollY === 0 ? ['invisible'] : ['visible'],
          )}
        >
          {showBottomButton && (
            <div className="inline-block h-8 uppercase text-white drop-shadow-xl duration-300 ease-out hover:h-14">
              <a
                onClick={(e) => scrollAndTrackApplyNow(e)}
                className="rounded-t-lg bg-pink-hot px-7 pt-2.5 pb-8 hover:cursor-pointer"
              >
                {t('footer.apply_now')}
              </a>
            </div>
          )}
        </div>
      </div>
      <CookieModal
        isManageCookiesModalVisible={isManageCookiesModalVisible}
        setIsManageCookiesModalVisible={setIsManageCookiesModalVisible}
      />
    </section>
  );
};

export default Footer;
