import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CookieModal from './CookieModal';
import { getCookieConsentValue, setCookieConsent } from '../helpers/cookies';

const CookieConsent = () => {
  const { t } = useTranslation();
  const [isManageCookiesModalVisible, setIsManageCookiesModalVisible] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  const acceptAll = () => {
    setCookieConsent(true);
    setIsManageCookiesModalVisible(false);
    location.reload();
  };

  const manage = async () => {
    await setIsManageCookiesModalVisible(true);
  };

  useEffect(() => {
    const cookieConsent = getCookieConsentValue();

    if (cookieConsent === 'true' || cookieConsent === 'false') {
      setShowCookieConsent(false);
    } else {
      setShowCookieConsent(true);
    }
  }, []);

  return (
    <>
      {showCookieConsent && !isManageCookiesModalVisible && (
        <div className="fixed bottom-0 left-0 z-50 w-full border-t border-gray-200 bg-white">
          <div className="container mx-auto flex items-center justify-between gap-4 px-4 py-4">
            <div className="text-sm text-gray-600">
              {t('cookie_consent')} {` `}
              <Link to="/privacy" className="underline">{`${t('cookie_consent_link')}`}</Link>
            </div>
            <button className="manage-button" onClick={manage}>
              {t('cookie_consent_manage')}
            </button>
            <button className="accept-button" onClick={acceptAll}>
              {t('cookie_consent_accept_all')}
            </button>
          </div>
        </div>
      )}
      <CookieModal
        isManageCookiesModalVisible={isManageCookiesModalVisible}
        setIsManageCookiesModalVisible={setIsManageCookiesModalVisible}
      />
    </>
  );
};

export default CookieConsent;
