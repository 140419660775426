import type { CSSProperties } from 'react';

interface RoundedParallelogramProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  fill?: CSSProperties['fill'];
  className?: string;
}

export const RoundedParallelogram = ({
  width,
  height,
  fill = '#F97FB5',
  className,
}: RoundedParallelogramProps) => (
  <figure className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 627 459"
      fill="none"
    >
      <path
        d="M27.5343 82.6659C32.1484 35.7375 70.9746 0.000244141 117.345 0.000244141H536.733C590.132 0.000244141 631.857 46.8933 626.543 100.935L599.466 376.335C594.852 423.263 556.026 459 509.655 459H90.2675C36.8685 459 -4.85667 412.107 0.456728 358.066L27.5343 82.6659Z"
        fill={fill}
      />
    </svg>
  </figure>
);
